import AVAILABLE_HEADER_MAPPINGS from "../data/available_header_mappings.json";

export function CSVContentToHeaderMapper(csvContent) {
  this.headers = csvContent[0];
  this.previewContent = csvContent[1];
  this.initialMapping = {};
  this.tableHeaders = ["CSV Header Row", "Row Preview", "Header Mapping"];
  this.table = document.createElement("table");
  this.thead = document.createElement("thead");
  this.tbody = document.createElement("tbody");
}

CSVContentToHeaderMapper.prototype.contentToTableWithHeaders = function () {
  this.parseHeaders();
  this.createTableHeader();
  return this.createTable();
};

CSVContentToHeaderMapper.prototype.parseHeaders = function () {
  this.headers.forEach((header, index) => {
    const parsedHeader = header.trim();
    const preview = this.previewContent[index].trim();
    let columnMapping;
    if (window.formerHeaderMappings && window.formerHeaderMappings[parsedHeader]) {
      columnMapping = window.formerHeaderMappings[parsedHeader];
    } else {
      columnMapping = parsedHeader;
    }
    this.initialMapping[parsedHeader] = {
      raw_file_header: parsedHeader,
      cell_preview: preview,
      column_mapping: columnMapping,
    };
  });
};

CSVContentToHeaderMapper.prototype.createTableHeader = function () {
  // Create table header
  this.thead.classList.add("bg-onyx-50");
  const headerRow = document.createElement("tr");
  this.tableHeaders.forEach((header) => {
    const th = document.createElement("th");
    th.textContent = header;
    headerRow.appendChild(th);
    th.classList.add("text-xs", "text-onyx-500", "font-semibold", "uppercase", "leading-4", "px-6", "py-3");
  });
  this.thead.appendChild(headerRow);
};

CSVContentToHeaderMapper.prototype.createTable = function () {
  const mappingValues = Object.values(this.initialMapping);
  mappingValues.forEach((mapping) => {
    const row = document.createElement("tr");
    row.classList.add("bg-white", "dark:bg-gray-800", "border-b", "last:border-b-0");
    Object.values(mapping).forEach((value, index) => {
      const td = document.createElement("td");
      td.classList.add("px-6", "py-3", "text-onyx-950", "dark:text-gray-400");
      // if it is the last cell in the row, add a select dropdown
      if (index === Object.values(mapping).length - 1) {
        const select = this.createSelect(value);
        td.appendChild(select);
      } else {
        td.textContent = value;
      }
      row.appendChild(td);
    });
    this.tbody.appendChild(row);
  });

  this.table.appendChild(this.thead);
  this.table.classList.add("table-fixed", "w-full", "text-left", "rounded-lg");
  this.table.appendChild(this.tbody);
  return this.table;
};

CSVContentToHeaderMapper.prototype.createSelect = function (value) {
  const select = document.createElement("select");
  select.classList.add(
    "w-full",
    "bg-white",
    "rounded-lg",
    "border",
    "border-onyx-300",
    "h-9",
    "px-2.5",
    "text-onyx-500",
  );
  // Add options to the select dropdown
  AVAILABLE_HEADER_MAPPINGS.forEach((header) => {
    const option = document.createElement("option");
    option.value = header;
    option.textContent = header;
    select.appendChild(option);
  });
  select.value = value;
  return select;
};

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleAll() {
    const checkboxes = Array.from(
      this.element.querySelectorAll(
        'div[id="providers-export-tabs-content"] input[type="checkbox"]:not(#select-all-filter)',
      ),
    );
    const selectAllFilter = this.element.querySelector('div[id="providers-export-tabs-content"] #select-all-filter');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = selectAllFilter.checked;
    });
  }

  setSource(event) {
    const source = event.target;
    if (source) {
      this.element.querySelector("#dropdown-button-title").innerText = source.innerText.trim();
      this.element.querySelector("#data_source").value = source.dataset.value;
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import { CSVContentToHeaderMapper } from "../utilities/csv_content_to_header_mapper";
import papaparse from "papaparse";

// Connects to data-controller="import-file-uploader"
export default class extends Controller {
  connect() {
    const fileInput = this.element.querySelector("#file-upload");
    fileInput.addEventListener("change", this.onFileUpload);
    const createImportButton = document.querySelector("#create-import");
    createImportButton.addEventListener("click", this.handleCreateImport);
  }

  handleCreateImport = (event) => {
    event.preventDefault();
    const mappingFields = document.querySelector("#mapping-fields");
    const tableRows = document.querySelectorAll("#mapping-fields-form tbody tr");
    const mapping = {};

    tableRows.forEach((row) => {
      const cells = row.querySelectorAll("td");
      const rawFileHeader = cells[0].textContent;
      const columnMapping = cells[2].querySelector("select").value;
      mapping[rawFileHeader] = columnMapping;
    });

    mappingFields.value = JSON.stringify(mapping);
    event.target.closest("form").submit();
  };

  processFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target.result;
      papaparse.parse(csvData, {
        complete: (results) => {
          const mapper = new CSVContentToHeaderMapper(results.data);
          const table = mapper.contentToTableWithHeaders();
          const mappingFieldsForm = document.querySelector("#mapping-fields-form");
          mappingFieldsForm.innerHTML = "";
          mappingFieldsForm.appendChild(table);
        },
      });
    };
    reader.readAsText(file);
  };

  onFileUpload = (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      this.processFile(files[0]);
    }
  };
}
